import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// URLs de los endpoints del backend
const URL_SHOWVENTAS = "https://evnd-database.com/roger/Proyecto_FI/backend_art/mostrar_historial.php";
const URL_ADDVENTA = "https://evnd-database.com/roger/Proyecto_FI/backend_art/insertar_historial.php";
const URL_EDITVENTA = "https://evnd-database.com/roger/Proyecto_FI/backend_art/editar_historial.php";
const URL_DELETEVENTA = "https://evnd-database.com/roger/Proyecto_FI/backend_art/eliminar_historial.php";
const URL_SHOWESTADOS = "https://evnd-database.com/roger/Proyecto_FI/backend_art/mostrar_estado.php";

export default function CrudVentas() {
  const navigate = useNavigate();
  const [ventas, setVentas] = useState([]); // Lista de ventas
  const [estados, setEstados] = useState([]); // Lista de estados
  const [modalVisible, setModalVisible] = useState(false); // Control del modal
  const [isEditing, setIsEditing] = useState(false); // Estado para edición o creación
  const [formData, setFormData] = useState({
    id_historial: "",
    id_producto: "",
    direccion: "",
    nombre: "",
    cantidad: "",
    id_estado: "",
    fecha: "",
  });

  // Función para cargar los datos iniciales
  const fetchData = async () => {
    try {
      const response = await fetch(URL_SHOWVENTAS);
      const data = await response.json();
      setVentas(data.data);
    } catch (error) {
      console.error("Error al cargar las ventas:", error);
    }
  };

  const fetchEstados = async () => {
    try {
      const response = await fetch(URL_SHOWESTADOS);
      const data = await response.json();
      setEstados(data.data);
    } catch (error) {
      console.error("Error al cargar los estados:", error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchEstados();
  }, []);

  // Control del modal
  const toggleModal = () => setModalVisible(!modalVisible);

  // Actualización de los campos del formulario
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Preparar el formulario para añadir una venta
  const handleAdd = () => {
    setFormData({
      id_historial: "",
      id_producto: "",
      direccion: "",
      nombre: "",
      cantidad: "",
      id_estado: "",
      fecha: "",
    });
    setIsEditing(false);
    toggleModal();
  };

  // Preparar el formulario para editar una venta
  const handleEdit = (item) => {
    setFormData({
      id_historial: item.id_historial,
      id_producto: item.id_producto,
      direccion: item.direccion,
      nombre: item.nombre,
      cantidad: item.cantidad,
      id_estado: item.id_estado,
      fecha: item.fecha,
    });
    setIsEditing(true);
    toggleModal();
  };

  // Eliminar una venta
  const handleDelete = async (id) => {
    if (!window.confirm("¿Estás seguro de eliminar esta venta?")) return;
    try {
      const response = await fetch(URL_DELETEVENTA, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ id_historial: id }),
      });
      const data = await response.json();
      if (data.success) {
        alert(data.message);
        fetchData();
      } else {
        alert("Error al eliminar la venta.");
      }
    } catch (error) {
      console.error("Error al eliminar:", error);
    }
  };

  // Enviar datos al backend para añadir o editar
  const handleSubmit = async (e) => {
    e.preventDefault();

    const url = isEditing ? URL_EDITVENTA : URL_ADDVENTA;

    // Datos a enviar como JSON
    const formDataToSend = {
      id_historial: formData.id_historial,
      id_producto: formData.id_producto,
      direccion: formData.direccion,
      nombre: formData.nombre,
      cantidad: formData.cantidad,
      id_estado: formData.id_estado,
      fecha: formData.fecha,
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formDataToSend), // Enviar el objeto como JSON
      });
      const data = await response.json();

      if (data.success) {
        alert(data.message);
        fetchData();
        toggleModal();
      } else {
        alert("Error al procesar la solicitud.");
      }
    } catch (error) {
      console.error("Error en la solicitud:", error);
    }
  };

  return (
    <div>
      <h1 className="text-center">CRUD de Ventas</h1>
      <button className="btn btn-primary" onClick={() => navigate("/adm")}>
        Regresar
      </button>
      <button className="btn btn-primary" onClick={handleAdd}>
        Añadir Venta
      </button>
      <br />
      <br />
      <div className="row">
        {ventas.map((venta) => (
          <div className="col-md-4 mb-4" key={venta.id_historial}>
            <div className="card shadow-sm">
              <div className="card-body">
                <h5 className="card-title">{venta.nombre}</h5>
                <p className="card-text">Cantidad: {venta.cantidad}</p>
                <p className="card-text">Dirección: {venta.direccion}</p>
                <p className="card-text">
                  <strong>Estado:</strong> {venta.id_estado}
                </p>
                <p className="card-text">
                  <strong>Fecha:</strong> {new Date(venta.fecha).toLocaleString()}
                </p>
                <button
                  className="btn btn-warning me-2"
                  onClick={() => handleEdit(venta)}
                >
                  Editar
                </button>
                <button
                  className="btn btn-danger"
                  onClick={() => handleDelete(venta.id_historial)}
                >
                  Eliminar
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {modalVisible && (
        <div className="modal show" style={{ display: "block" }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  {isEditing ? "Editar Venta" : "Añadir Venta"}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={toggleModal}
                ></button>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="modal-body">
                  <div className="mb-3">
                    <label className="form-label">Producto ID</label>
                    <input
                      type="text"
                      className="form-control"
                      name="id_producto"
                      value={formData.id_producto}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Nombre</label>
                    <input
                      type="text"
                      className="form-control"
                      name="nombre"
                      value={formData.nombre}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Cantidad</label>
                    <input
                      type="number"
                      className="form-control"
                      name="cantidad"
                      value={formData.cantidad}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Dirección</label>
                    <input
                      type="text"
                      className="form-control"
                      name="direccion"
                      value={formData.direccion}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Estado</label>
                    <select
                      className="form-control"
                      name="id_estado"
                      value={formData.id_estado}
                      onChange={handleInputChange}
                      required
                    >
                      {estados.map((estado) => (
                        <option key={estado.id_estado} value={estado.id_estado}>
                          {estado.nombre_estado}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Fecha</label>
                    <input
                      type="datetime-local"
                      className="form-control"
                      name="fecha"
                      value={formData.fecha}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={toggleModal}
                  >
                    Cancelar
                  </button>
                  <button type="submit" className="btn btn-primary">
                    {isEditing ? "Guardar Cambios" : "Añadir"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

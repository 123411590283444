import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Navbar from "./Navbar"; // Importar la Navbar

const URL_PRODUCTS = "https://evnd-database.com/roger/Proyecto_FI/backend_art/mostrararti.php";

export default function DetalleArticulo() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [producto, setProducto] = useState(null);
  const [productos, setProductos] = useState([]);

  useEffect(() => {
    // Fetch all products
    fetch(URL_PRODUCTS)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setProductos(data);
        // Find the selected product by ID
        const selectedProduct = data.find((prod) => prod.id === id);
        setProducto(selectedProduct);
      })
      .catch((error) => console.error("Error fetching products:", error));
  }, [id]);

  if (!producto) return <div>Cargando...</div>;

  return (
    <div>
      {/* Navbar */}
      <Navbar />

      {/* Detalle del Producto */}
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-6">
            <img
              src={`https://evnd-database.com/roger/Proyecto_FI/backend_art/${producto.image_url}`}
              alt={producto.name}
              style={{ maxWidth: "100%", borderRadius: "10px" }}
            />
          </div>
          <div className="col-md-6">
            <h1>{producto.name}</h1>
            <p>{producto.description}</p>
            <p>
              <strong>Precio:</strong> ${producto.price}
            </p>
            <p>
              <strong>Stock:</strong> {producto.stock}
            </p>
            <button
              className="btn btn-primary"
              onClick={() => navigate(`/comprar/${producto.id}`)}>
                     Comprar
            </button>
          </div>
        </div>

        {/* Otros Productos */}
        <h2 className="mt-5">Otros productos</h2>
        <div className="row">
          {productos
            .filter((prod) => prod.id !== id) // Excluir el producto actual
            .map((prod) => (
              <div className="col-md-3 mb-4" key={prod.id}>
                <div
                  className="card"
                  onClick={() => navigate(`/articulo/${prod.id}`)}
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={`https://evnd-database.com/roger/Proyecto_FI/backend_art/${prod.image_url}`}
                    className="card-img-top"
                    alt={prod.name}
                    style={{ maxHeight: "200px", objectFit: "cover" }}
                  />
                  <div className="card-body">
                    <h5 className="card-title">{prod.name}</h5>
                    <p className="card-text text-truncate">{prod.description}</p>
                    <p className="card-text">
                      <strong>Precio:</strong> ${prod.price}
                    </p>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

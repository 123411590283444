import React from "react";
import Navbar from "./Navbar"; // Importar la Navbar
import { useNavigate } from "react-router-dom";

export default function About() {
    const navigate = useNavigate();
  return (
    <div>
      {/* Navbar */}
      <Navbar />

      {/* Sección de la Empresa */}
      <div className="container mt-5">
        <h1 className="text-center mb-4">Sobre Arte Sublimado</h1>

        <div className="row">
          <div className="col-md-6">
            <img
              src="/map.png" // Imagen de ejemplo
              alt="Arte Sublimado"
              style={{ maxWidth: "100%", borderRadius: "10px" }}
            />
          </div>
          <div className="col-md-6">
            <p>
              <strong>Arte Sublimado</strong> nace de la pasión por el diseño y la creatividad de un grupo de jóvenes emprendedores.
              Con años de experiencia en el mundo del diseño gráfico y la impresión digital, hemos perfeccionado la técnica de sublimación
              para ofrecerte productos personalizados de la más alta calidad. Creemos que cada diseño es una oportunidad para expresar tu
              individualidad, y por eso trabajamos contigo para convertir tus ideas en realidad.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

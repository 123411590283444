import React, { useRef, useState } from "react";
import { useNavigate, Link } from "react-router-dom"; 
import '../css/animarlogo.css'; // Asegúrate de importar el archivo CSS para la animación

const URL_LOGIN = "https://evnd-database.com/roger/Proyecto_FI/backend_art/login.php";

// Función para enviar datos al servidor
const enviarData = async (url, data) => {
  const resp = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const json = await resp.json();
  return json;
};

export default function Login(props) {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [espera, setEspera] = useState(false);
  const refUsuario = useRef(null);
  const refClave = useRef(null);

  // Función para manejar el inicio de sesión
  const handleLogin = async () => {
    setEspera(true);
    const data = {
      "usuario": refUsuario.current.value,
      "clave": refClave.current.value
    };

    console.log(data);
    const respuestaJson = await enviarData(URL_LOGIN, data);
    console.log("Respuesta desde el evento", respuestaJson);

    if (respuestaJson.conectado) {
      // Si la autenticación es exitosa, guardamos la sesión en localStorage
      localStorage.setItem("autenticado", JSON.stringify(respuestaJson));

      // Si el idTipoUsuario es menor que 4, redirigir al Dashboard
      if (respuestaJson.idTipoUsuario < 4) {
        props.acceder(true); // Indica que el usuario está conectado
        navigate("/adm"); // Redirige al Dashboard
      } else {
        // Si el idTipoUsuario es 4 o mayor, redirigir al Menu
        props.acceder(true); // Indica que el usuario está conectado
        navigate("/"); // Redirige al Menu
      }
    } else {
      // Si la autenticación falla, mostrar el error
      setError(respuestaJson.error);
    }
    setEspera(false);
  };

  return (
    <section className="p-3 p-md-4 p-xl-5">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 bsb-tpl-bg-platinum">
            <div className="d-flex flex-column justify-content-between h-100 p-3 p-md-4 p-xl-5">
              <h3 className="m-0 text-center font-weight-bold" style={{ fontSize: '2.5rem', letterSpacing: '1px', color: '#c069ef' }}>
                Bienvenido a Arte Sublimado
              </h3>

              <img
                className="img-fluid rounded mx-auto my-4 logo-animate"
                loading="lazy"
                src="./artesublim.png"
                width={245}
                height={80}
                alt="Arte Sublimado Logo"
              />
              <p className="mb-0 text-center">
                ¿No eres miembro aún?{" "}
                <Link to="/regis" className="link-secondary text-decoration-none">
                  Regístrate ahora
                </Link>
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6 bsb-tpl-bg-lotion">
            <div className="p-3 p-md-4 p-xl-5">
              <div className="row">
                <div className="col-12">
                  <div className="mb-5">
                    <h3>Iniciar Sesión</h3>
                  </div>
                </div>
              </div>
              <form action="#!">
                <div className="row gy-3 gy-md-4 overflow-hidden">
                  <div className="col-12">
                    <label htmlFor="email" className="form-label">
                      Email <span className="text-danger">*</span>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      id="email"
                      placeholder="name@example.com"
                      required
                      ref={refUsuario}
                    />
                  </div>
                  <div className="col-12">
                    <label htmlFor="password" className="form-label">
                      Password <span className="text-danger">*</span>
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      id="password"
                      required
                      ref={refClave}
                    />
                  </div>
                  <div className="col-12">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="remember_me"
                        id="remember_me"
                      />
                      <label className="form-check-label text-secondary" htmlFor="remember_me">
                        Mantenerme conectado
                      </label>
                    </div>
                  </div>
                  {error && (
                    <div className="alert alert-danger">
                      {error}
                    </div>
                  )}
                  <div className="col-12">
                    <div className="d-grid">
                      <button onClick={handleLogin} disabled={espera} className="btn bsb-btn-xl btn-primary" type="button">
                        Iniciar Sesión
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              <div className="row">
                <div className="col-12">
                  <hr className="mt-5 mb-4 border-secondary-subtle" />
                  <div className="text-end">
                    <a href="#!" className="link-secondary text-decoration-none">
                      Olvidé mi contraseña
                    </a>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <p className="mt-5 mb-4">O inicia sesión con</p>
                  <div className="d-flex gap-3 flex-column flex-xl-row">
                    {/* Aquí irían los botones para iniciar sesión con Google, Facebook, etc. */}
                    <a href="#!" className="btn bsb-btn-xl btn-outline-primary">
                      Google
                    </a>
                    <a href="#!" className="btn bsb-btn-xl btn-outline-primary">
                      Facebook
                    </a>
                    <a href="#!" className="btn bsb-btn-xl btn-outline-primary">
                      Twitter
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

import React from 'react';
import { useNavigate } from "react-router-dom";

function DashboardAdm() {
  // Llamamos a useNavigate dentro del componente funcional
  const navigate = useNavigate();

  // Función de cierre de sesión
  const handleLogout = () => {
    localStorage.removeItem("autenticado"); // Elimina el estado de autenticación
    navigate("/lgn"); // Redirige al login
  };

  return (
    <>
      <div id="wrapper">
        <div id="page-wrapper" className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              {/* Estilo personalizado para el título */}
              <h1 className="page-header" style={styles.dashboardTitle}>
                <i className="fa fa-tachometer-alt fa-fw" /> Dashboard
              </h1>
            </div>
          </div>

          {/* Main Content */}
          <div className="row">
            {/* Sidebar Buttons */}
            <div className="col-md-3">
              <div className="list-group">
                <button 
                  className="list-group-item list-group-item-action" 
                  style={styles.button}
                  onClick={() => navigate("/usuarios")}
                >
                  <i className="fa fa-users fa-fw" /> Administer Users
                </button>
                <button 
                  className="list-group-item list-group-item-action" 
                  style={styles.button}
                  onClick={() => navigate("/articulos")}
                >
                  <i className="fa fa-users fa-fw" /> Administrar Productos
                </button>
                <button className="list-group-item list-group-item-action" style={styles.button}
                  onClick={() => navigate("/carousel")}>
                  <i className="fa fa-bullhorn fa-fw" /> Manage Banners
                </button>
                <button className="list-group-item list-group-item-action" style={styles.button} 
                  onClick={() => navigate("/vents")}>
                  <i className="fa fa-cogs fa-fw" /> Ventas
                </button>
              </div>
            </div>

            {/* Content Panels */}
            <div className="col-md-9">
              <div className="row">
                {/* Overview Panel */}
                <div className="col-lg-6">
                  <div className="panel" style={styles.panel('primary')}>
                    <div className="panel-heading" style={styles.panelHeading}>
                      <i className="fa fa-dashboard fa-fw" /> Overview
                    </div>
                    <div className="panel-body">
                      <p>Here you can get an overview of your data and stats.</p>
                    </div>
                  </div>
                </div>

                {/* Statistics Panel */}
                <div className="col-lg-6">
                  <div className="panel" style={styles.panel('success')}>
                    <div className="panel-heading" style={styles.panelHeading}>
                      <i className="fa fa-bar-chart fa-fw" /> Statistics
                    </div>
                    <div className="panel-body">
                      <p>Key performance indicators and metrics are displayed here.</p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Additional Panels */}
              <div className="row">
                <div className="col-lg-6">
                  <div className="panel" style={styles.panel('warning')}>
                    <div className="panel-heading" style={styles.panelHeading}>
                      <i className="fa fa-calendar fa-fw" /> Events
                    </div>
                    <div className="panel-body">
                      <p>Manage upcoming events and important dates here.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="panel" style={styles.panel('info')}>
                    <div className="panel-heading" style={styles.panelHeading}>
                      <i className="fa fa-cogs fa-fw" /> System Settings
                    </div>
                    <div className="panel-body">
                      <p>Configure system settings and preferences.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Cerrar sesión button */}
          <div className="d-flex justify-content-end mt-4">
            <button
              className="btn btn-danger"
              style={styles.logoutButton}
              onClick={handleLogout}
            >
              <i className="fa fa-sign-out-alt" /> Cerrar sesión
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

// Estilos personalizados
const styles = {
  dashboardTitle: {
    color: '#ffffff',
    backgroundColor: '#c069ef',
    padding: '15px',
    borderRadius: '10px',
    textAlign: 'center',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    fontFamily: "'Roboto', sans-serif",
    fontSize: '36px',
    letterSpacing: '2px',
    marginBottom: '30px'
  },
  button: {
    backgroundColor: '#1e90ff',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    margin: '5px 0',
    fontWeight: 'bold',
    transition: 'all 0.3s ease',
  },
  panel: (color) => ({
    borderColor: color === 'primary' ? '#007bff' : color === 'success' ? '#28a745' : color === 'warning' ? '#ffc107' : '#17a2b8',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    marginBottom: '30px',
  }),
  panelHeading: {
    backgroundColor: '#f5f5f5',
    color: '#333',
    padding: '10px 15px',
    fontSize: '18px',
    fontWeight: 'bold',
    borderRadius: '8px 8px 0 0',
  },
  logoutButton: {
    fontSize: '16px',
    fontWeight: 'bold',
    padding: '10px 20px',
    marginRight: '20px',
  }
};

export default DashboardAdm;

import React, { useRef, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import bcrypt from "bcryptjs"; // Importar bcryptjs
import '../css/animarlogo.css'; // Importa el archivo CSS para la animación

const URL_REGISTRO = "https://evnd-database.com/roger/Proyecto_FI/backend_art/anadirUsua.php"; // Endpoint de registro

const enviarData = async (url, data) => {
  const resp = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const json = await resp.json();
  return json;
};

export default function Registro(props) {
    const navigate = useNavigate();  // Hook para redirección
  const [error, setError] = useState(null);
  const [espera, setEspera] = useState(false);
  const refUsuario = useRef(null);
  const refClave = useRef(null);
  const refNombre = useRef(null);
  const refApellidos = useRef(null);

  const handleRegistro = async () => {
    setEspera(true);
    
    // Obtener el valor de la clave (contraseña) y encriptarla con bcrypt
    const plainPassword = refClave.current.value;
    const hashedPassword = await bcrypt.hash(plainPassword, 10); // El número 10 es el "salt rounds"

    const data = {
      "usuario": refUsuario.current.value,
      "clave": hashedPassword, // Usar la contraseña encriptada
      "nombre": refNombre.current.value,
      "apellidos": refApellidos.current.value,
      "idTipoUsuario": 4, // Asignamos por defecto el idTipoUsuario=4
    };

    console.log(data);
    const respuestaJson = await enviarData(URL_REGISTRO, data);
    console.log("Respuesta desde el evento", respuestaJson);

    if (respuestaJson.success) {
      // Redirigir al login después del registro exitoso
      navigate("/");  // Asumiendo que "/" es la ruta de login
    } else {
      setError(respuestaJson.message);
    }
    setEspera(false);
  };

  return (
    <section className="p-3 p-md-4 p-xl-5">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 bsb-tpl-bg-platinum">
            <div className="d-flex flex-column justify-content-between h-100 p-3 p-md-4 p-xl-5">
              <h3 className="m-0 text-center font-weight-bold" style={{ fontSize: '2.5rem', letterSpacing: '1px', color: '#c069ef' }}>
                Bienvenido a Arte Sublimado
              </h3>

              <img
                className="img-fluid rounded mx-auto my-4 logo-animate"
                loading="lazy"
                src="./artesublim.png"
                width={245}
                height={80}
                alt="Arte Sublimado Logo"
              />
              <p className="mb-0 text-center">
                ¿Ya tienes una cuenta?{" "}
                <Link to="/" className="link-secondary text-decoration-none">
                  Iniciar Sesión
                </Link>
              </p>
            </div>
          </div>

          <div className="col-12 col-md-6 bsb-tpl-bg-lotion">
            <div className="p-3 p-md-4 p-xl-5">
              <div className="row">
                <div className="col-12">
                  <div className="mb-5">
                    <h3>Registrarse</h3>
                  </div>
                </div>
              </div>
              <form action="#!">
                <div className="row gy-3 gy-md-4 overflow-hidden">
                  <div className="col-12">
                    <label htmlFor="usuario" className="form-label">
                      Correo <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="usuario"
                      id="usuario"
                      placeholder="Nombre de usuario"
                      required
                      ref={refUsuario}
                    />
                  </div>
                  <div className="col-12">
                    <label htmlFor="nombre" className="form-label">
                      Nombre <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="nombre"
                      id="nombre"
                      placeholder="Tu nombre"
                      required
                      ref={refNombre}
                    />
                  </div>
                  <div className="col-12">
                    <label htmlFor="apellidos" className="form-label">
                      Apellidos <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="apellidos"
                      id="apellidos"
                      placeholder="Tus apellidos"
                      required
                      ref={refApellidos}
                    />
                  </div>
                  <div className="col-12">
                    <label htmlFor="clave" className="form-label">
                      Contraseña <span className="text-danger">*</span>
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      name="clave"
                      id="clave"
                      required
                      ref={refClave}
                    />
                  </div>
                  {error && (
                    <div className="alert alert-danger">
                      {error}
                    </div>
                  )}
                  <div className="col-12">
                    <div className="d-grid">
                      <button onClick={handleRegistro} disabled={espera} className="btn bsb-btn-xl btn-primary" type="button">
                        Registrarse
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// URLs de los endpoints del backend
const URL_SHOWART = "https://evnd-database.com/roger/Proyecto_FI/backend_art/mostrararti.php";
const URL_ADDART = "https://evnd-database.com/roger/Proyecto_FI/backend_art/anadirArti.php";
const URL_EDITART = "https://evnd-database.com/roger/Proyecto_FI/backend_art/editarArti.php";
const URL_DELETEART = "https://evnd-database.com/roger/Proyecto_FI/backend_art/eliminarArti.php";
const URL_SHOWCAT = "https://evnd-database.com/roger/Proyecto_FI/backend_art/mostrarCategorias.php";

// Componente principal
export default function Crud() {
  const navigate = useNavigate();
  const [productos, setProductos] = useState([]); // Lista de productos
  const [modalVisible, setModalVisible] = useState(false); // Control del modal
  const [isEditing, setIsEditing] = useState(false); // Estado para edición o creación
  const [formData, setFormData] = useState({
    id: "",
    name: "",
    description: "",
    price: "",
    stock: "",
    category_id: "",
    image: null,
  });

  // Función para cargar los datos iniciales
  const fetchData = async () => {
    try {
      const response = await fetch(URL_SHOWART);
      const data = await response.json();
      console.log(data);
      setProductos(data);
    } catch (error) {
      console.error("Error al cargar los productos:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Control del modal
  const toggleModal = () => setModalVisible(!modalVisible);

  // Actualización de los campos del formulario
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Manejo de la imagen
  const handleFileChange = (e) => {
    setFormData({ ...formData, image: e.target.files[0] });
  };

  // Preparar el formulario para añadir un producto
  const handleAdd = () => {
    setFormData({
      id: "",
      name: "",
      description: "",
      price: "",
      stock: "",
      category_id: "",
      image: null,
    });
    setIsEditing(false);
    toggleModal();
  };

  // Preparar el formulario para editar un producto
  const handleEdit = (item) => {
    setFormData({
      id: item.id,
      name: item.name,
      description: item.description,
      price: item.price,
      stock: item.stock,
      category_id: item.category_id,
      image: null,
    });
    setIsEditing(true);
    toggleModal();
  };

  // Eliminar un producto
  const handleDelete = async (id) => {
    if (!window.confirm("¿Estás seguro de eliminar este producto?")) return;
    try {
      const response = await fetch(URL_DELETEART, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ id }),
      });
      const data = await response.json();
      if (data.success) {
        alert(data.message);
        fetchData();
      } else {
        alert("Error al eliminar el producto.");
      }
    } catch (error) {
      console.error("Error al eliminar:", error);
    }
  };

  // Enviar datos al backend para añadir o editar
  const handleSubmit = async (e) => {
    e.preventDefault();

    const url = isEditing ? URL_EDITART : URL_ADDART;
    const method = "POST";

    const formDataToSend = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      if (value !== null) {
        formDataToSend.append(key, value);
      }
    });

    try {
      const response = await fetch(url, { method, body: formDataToSend });
      const data = await response.json();

      if (data.success) {
        alert(data.message);
        fetchData();
        toggleModal();
      } else {
        alert("Error al procesar la solicitud.");
      }
    } catch (error) {
      console.error("Error en la solicitud:", error);
    }
  };

  return (
    <div>
      <h1 className="text-center">CRUD de Productos</h1>
      <button className="btn btn-primary" onClick={() => navigate("/adm")}>
        Regresar
      </button>
      <button className="btn btn-primary" onClick={handleAdd}>
        Añadir Producto
      </button>
       
      <br />
      <br />
      <div className="row">
        {productos.map((prod, index) => (
          <div className="col-md-4 mb-4" key={`${prod.id}-${index}`}>
            <div className="card shadow-sm">
              {/* Mostrar imagen del producto */}
              <img
                src={`https://evnd-database.com/roger/Proyecto_FI/backend_art/${prod.image_url}`} // Asegúrate de que image_path es correcto
                className="card-img-top"
                alt={prod.name} // Agregar un alt para accesibilidad
              />
              <div className="card-body">
                <h5 className="card-title">{prod.name}</h5>
                <p className="card-text">{prod.description}</p>
                <p className="card-text">
                  <strong>Precio:</strong> ${prod.price}
                </p>
                <p className="card-text">
                  <strong>Stock:</strong> {prod.stock}
                </p>
                <p className="card-text">
                  <strong>Categoría:</strong> {prod.category_id}
                </p>
                <button
                  className="btn btn-warning me-2"
                  onClick={() => handleEdit(prod)}
                >
                  Editar
                </button>
                <button
                  className="btn btn-danger"
                  onClick={() => handleDelete(prod.id)}
                >
                  Eliminar
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {modalVisible && (
        <div className="modal show" style={{ display: "block" }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  {isEditing ? "Editar Producto" : "Añadir Producto"}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={toggleModal}
                ></button>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="modal-body">
                  <div className="mb-3">
                    <label className="form-label">Nombre</label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Descripción</label>
                    <input
                      type="text"
                      className="form-control"
                      name="description"
                      value={formData.description}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Precio</label>
                    <input
                      type="number"
                      className="form-control"
                      name="price"
                      value={formData.price}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Stock</label>
                    <input
                      type="number"
                      className="form-control"
                      name="stock"
                      value={formData.stock}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Categoría</label>
                    <input
                      type="text"
                      className="form-control"
                      name="category_id"
                      value={formData.category_id}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Imagen</label>
                    <input
                      type="file"
                      className="form-control"
                      onChange={handleFileChange}
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={toggleModal}
                  >
                    Cancelar
                  </button>
                  <button type="submit" className="btn btn-primary">
                    {isEditing ? "Guardar Cambios" : "Añadir"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar"; // Importa el componente Navbar

const URL_SHOWCAT = "https://evnd-database.com/roger/Proyecto_FI/backend_art/mostrarCategorias.php";
const URL_SHOWART = "https://evnd-database.com/roger/Proyecto_FI/backend_art/mostrararti.php";

export default function CategoriasCrud() {
  const [categorias, setCategorias] = useState([]); // Lista de categorías
  const [productosPorCategoria, setProductosPorCategoria] = useState({}); // Productos agrupados por categoría
  const [loading, setLoading] = useState(true); // Estado de carga
  const navigate = useNavigate();

  // Obtener categorías
  const fetchCategorias = async () => {
    try {
      const response = await fetch(URL_SHOWCAT);
      const data = await response.json();
      setCategorias(data);
    } catch (error) {
      console.error("Error al cargar las categorías:", error);
    }
  };

  // Obtener productos
  const fetchProductos = async () => {
    try {
      const response = await fetch(URL_SHOWART);
      const data = await response.json();

      // Agrupar productos por categoría
      const productosAgrupados = data.reduce((acc, producto) => {
        const categoria = producto.category_id;
        if (!acc[categoria]) acc[categoria] = [];
        acc[categoria].push(producto);
        return acc;
      }, {});
      setProductosPorCategoria(productosAgrupados);
    } catch (error) {
      console.error("Error al cargar los productos:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategorias();
    fetchProductos();
  }, []);

  if (loading) {
    return <div>Cargando...</div>;
  }

  return (
    <div>
      {/* Navbar */}
      <Navbar />

      {/* Categorías */}
      <div className="accordion mt-4" id="categoriasAccordion">
        {categorias.map((categoria, index) => (
          <div className="accordion-item" key={index}>
            <h2 className="accordion-header" id={`heading${index}`}>
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#collapse${index}`}
                aria-expanded="false"
                aria-controls={`collapse${index}`}
              >
                {categoria}
              </button>
            </h2>
            <div
              id={`collapse${index}`}
              className="accordion-collapse collapse"
              aria-labelledby={`heading${index}`}
              data-bs-parent="#categoriasAccordion"
            >
              <div className="accordion-body">
                <div className="row">
                  {productosPorCategoria[categoria] ? (
                    productosPorCategoria[categoria].map((producto) => (
                      <div className="col-md-4 mb-3" key={producto.id}>
                        <div className="card">
                          <img
                            src={`https://evnd-database.com/roger/Proyecto_FI/backend_art/${producto.image_url}`}
                            className="card-img-top"
                            alt={producto.name}
                          />
                          <div className="card-body">
                            <h5 className="card-title">{producto.name}</h5>
                            <p className="card-text">{producto.description}</p>
                            <p className="card-text">
                              <strong>Precio:</strong> ${producto.price}
                            </p>
                            <p className="card-text">
                              <strong>Stock:</strong> {producto.stock}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No hay productos en esta categoría.</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";

export default function CartCash() {
  const [cartItems, setCartItems] = useState([]);
  const [historicalPurchases, setHistoricalPurchases] = useState([]);
  const [total, setTotal] = useState(0);
  const [error, setError] = useState(null);
  const [states, setStates] = useState([]);
  const [paypalLoaded, setPaypalLoaded] = useState(false);
  const [paypalButtonRendered, setPaypalButtonRendered] = useState(false);

  // Cargar SDK de PayPal
  const loadPayPalScript = () => {
    const script = document.createElement("script");
    script.src = "https://www.paypal.com/sdk/js?client-id=AeSxCbjUnpJQkXuJdl6d-wENTRWEXPZDvVKLBOaBcmQtq2GzOPvYccEsCDGyoIWDaTDmMogsKGw6OjcH&currency=MXN";
    script.addEventListener("load", () => {
      setPaypalLoaded(true); // Marcar como cargado el script de PayPal
    });
    document.body.appendChild(script);
  };

  // Renderizar el botón de PayPal solo una vez
  const renderPayPalButton = () => {
    if (!paypalLoaded || paypalButtonRendered) {
      return; // Evitar renderizar más de una vez
    }

    const container = document.getElementById("paypal-button-container");
    if (container) {
      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    value: total.toFixed(2), // Total del carrito
                  },
                },
              ],
            });
          },
          onApprove: (data, actions) => {
            return actions.order.capture().then((details) => {
              alert(`Pago completado por ${details.payer.name.given_name}`);
              // Lógica adicional después del pago
            });
          },
          onError: (err) => {
            console.error("Error con PayPal:", err);
            alert("Hubo un problema con PayPal. Intenta de nuevo.");
          },
        })
        .render("#paypal-button-container");

      setPaypalButtonRendered(true); // Marcar el botón como renderizado
    } else {
      console.error("El contenedor del botón de PayPal no está disponible.");
    }
  };

  // Obtener productos del carrito
  const fetchCartItems = async () => {
    try {
      const response = await fetch("https://evnd-database.com/roger/Proyecto_FI/backend_art/mostrarCarrito.php");
      if (!response.ok) throw new Error("Error al obtener los datos del carrito");
      const data = await response.json();
      if (data.success) {
        setCartItems(data.data || []);
        setTotal(data.total || 0);
      } else {
        throw new Error(data.message || "Error desconocido");
      }
    } catch (err) {
      setError(err.message);
    }
  };

  // Obtener historial de compras
  const fetchHistoricalPurchases = async () => {
    try {
      const response = await fetch("https://evnd-database.com/roger/Proyecto_FI/backend_art/mostrar_historial.php");
      if (!response.ok) throw new Error("Error al obtener el historial de compras");
      const data = await response.json();
      if (data.success) {
        setHistoricalPurchases(data.data || []);
      } else {
        throw new Error(data.message || "Error desconocido");
      }
    } catch (err) {
      setError(err.message);
    }
  };

  // Cargar los estados desde el backend
  const fetchStates = async () => {
    try {
      const response = await fetch("https://evnd-database.com/roger/Proyecto_FI/backend_art/mostrar_estado.php");
      if (!response.ok) throw new Error("Error al obtener los estados");
      const data = await response.json();
      if (data.success) {
        setStates(data.data || []);
      } else {
        throw new Error(data.message || "Error desconocido");
      }
    } catch (err) {
      setError(err.message);
    }
  };

  // Obtener el nombre del estado basado en el id_estado
  const getEstadoName = (idEstado) => {
    const estado = states.find((state) => state.id_estado === idEstado);
    return estado ? estado.nombre_estado : "Desconocido";
  };

  // Cargar datos y SDK de PayPal al montar el componente
  useEffect(() => {
    fetchCartItems();
    fetchHistoricalPurchases();
    fetchStates();
    loadPayPalScript();
  }, []);

  // Efecto para renderizar el botón cuando el carrito y PayPal estén listos
  useEffect(() => {
    if (cartItems.length > 0 && paypalLoaded) {
      renderPayPalButton(); // Renderizar el botón solo cuando PayPal esté listo y haya productos en el carrito
    }
  }, [cartItems, paypalLoaded]);

  return (
    <>
      <Navbar />
      <div className="container mt-5">
        <h2>Carrito de Compras</h2>
        {error && <div className="alert alert-danger">{error}</div>}
        {cartItems.length === 0 ? (
          <p>No hay productos en el carrito.</p>
        ) : (
          <div>
            <table className="table table-striped table-responsive">
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Dirección</th>
                  <th>Cantidad</th>
                  <th>Precio Unitario</th>
                  <th>Subtotal</th>
                  <th>Imagen</th>
                </tr>
              </thead>
              <tbody>
                {cartItems.map((item) => {
                  const precio = parseFloat(item.precio) || 0;
                  const subtotal = precio * item.cantidad;
                  return (
                    <tr key={item.id_producto}>
                      <td>{item.nombre}</td>
                      <td>{item.direccion}</td>
                      <td>{item.cantidad}</td>
                      <td>${precio.toFixed(2)}</td>
                      <td>${subtotal.toFixed(2)}</td>
                      <td>
                        {item.imagen ? (
                          <img
                            src={`https://evnd-database.com/roger/Proyecto_FI/backend_art/${item.imagen}`}
                            alt={item.nombre}
                            style={{ width: "80px", height: "80px" }}
                          />
                        ) : (
                          "Sin imagen"
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="text-end">
              <h4>Total: ${total.toFixed(2)}</h4>
              <div id="paypal-button-container"></div> {/* Botón de PayPal */}
            </div>
          </div>
        )}

        <h2 className="mt-5">Historial de Compras</h2>
        {historicalPurchases.length === 0 ? (
          <p>No hay compras previas.</p>
        ) : (
          <table className="table table-striped table-responsive">
            <thead>
              <tr>
                <th>Dirección</th>
                <th>Nombre</th>
                <th>Cantidad</th>
                <th>Imagen</th>
                <th>Estado</th>
                <th>Fecha</th>
              </tr>
            </thead>
            <tbody>
              {historicalPurchases.map((item) => (
                <tr key={item.id_historial}>
                  <td>{item.direccion}</td>
                  <td>{item.nombre}</td>
                  <td>{item.cantidad}</td>
                  <td>
                    {item.imagen ? (
                      <img
                        src={`https://evnd-database.com/roger/Proyecto_FI/backend_art/${item.imagen}`}
                        alt={item.nombre}
                        style={{ width: "80px", height: "80px" }}
                      />
                    ) : (
                      "Sin imagen"
                    )}
                  </td>
                  <td>{getEstadoName(item.id_estado)}</td>
                  <td>{new Date(item.fecha).toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
}

import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// Importación de componentes
import Login from './componentes/Login';
import Menu from './componentes/Menu';
import Usuarios from './componentes/crud_usuarios';
import Articu from './componentes/crud_articulos';
import DetArt from './componentes/DetalleArticulo';
import Admin from './componentes/dashboard-adm';
import Cart from './componentes/carritodecompra';
import AdmCaro from './componentes/admCarousel';
import { CartProvider } from './componentes/cartContext';
import Regritrado from './componentes/register';
import Abouts from './componentes/about';
import Cate from './componentes/Categorias';
import FormularioCompra from './componentes/FormularioCompra'; 
import VentasCR from './componentes/Ventascrud';

function App() {
  const [conectado, setConectado] = useState(false);

  // Verifica si ya hay una sesión activa
  useEffect(() => {
    const autenticado = localStorage.getItem("autenticado");
    if (autenticado) {
      setConectado(true);
    }
  }, []);

  // Función para manejar el estado de acceso
  const acceder = (estado) => {
    setConectado(estado);
  };

  return (
    <BrowserRouter>
      <CartProvider>
        {conectado ? (
          // Si está conectado, mostrar rutas privadas
          <Routes>
            <Route path="/" element={<Menu />} />
            <Route path="/usuarios" element={<Usuarios />} />
            <Route path="/articulos" element={<Articu />} />
            <Route path="/lgn" element={<Login acceder={acceder} />} />
            <Route path="/articulo/:id" element={<DetArt />} />
            <Route path="/comprar/:id" element={<FormularioCompra />} />
            <Route path="/adm" element={<Admin />} />
            <Route path="/carrito" element={<Cart />} />
            <Route path="/carousel" element={<AdmCaro />} />
            <Route path="/abo" element={<Abouts />} />
            <Route path="/cats" element={<Cate />} />
            <Route path="/vents" element={<VentasCR />} />
            <Route path="/regis" element={<Regritrado acceder={acceder} />} />
          </Routes>
        ) : (
          // Si no está conectado, mostrar solo login y registro
          <Routes>
            <Route path="/" element={<Login acceder={acceder} />} />
            <Route path="/regis" element={<Regritrado acceder={acceder} />} />
          </Routes>
        )}
      </CartProvider>
    </BrowserRouter>
  );
}

export default App;

    import React, { useState } from "react";
    import { useNavigate, useParams } from "react-router-dom";
    import Navbar from "./Navbar"; // Importar la Navbar

    export default function FormularioCompra() {
    const { id } = useParams(); // Obtener el ID del producto desde la URL
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        direccion: "",
        nombre: "",
        cantidad: 1,
        imagen: null, // Archivo de imagen
    });

    const handleChange = (e) => {
        const { name, value, files } = e.target;

        if (files) {
        // Almacenar directamente el archivo en el state
        setFormData((prevData) => ({
            ...prevData,
            [name]: files[0],
        }));
        } else {
        setFormData({
            ...formData,
            [name]: value,
        });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Crear un objeto FormData para enviar el archivo
        const formDataToSend = new FormData();
        formDataToSend.append("id_producto", id);
        formDataToSend.append("direccion", formData.direccion);
        formDataToSend.append("nombre", formData.nombre);
        formDataToSend.append("cantidad", formData.cantidad);
        if (formData.imagen) {
        formDataToSend.append("imagen", formData.imagen); // Adjuntar el archivo
        }

        // Enviar datos al backend
        fetch("https://evnd-database.com/roger/Proyecto_FI/backend_art/agregarCarrito.php", {
        method: "POST",
        body: formDataToSend, // Usar el FormData
        })
        .then((response) => {
            if (!response.ok) {
            throw new Error("Error al agregar al carrito");
            }
            return response.json();
        })
        .then((data) => {
            if (data.success) {
            alert(data.message);
            navigate("/carrito"); // Redirigir al carrito
            } else {
            alert(data.message);
            }
        })
        .catch((error) => console.error("Error:", error));
    };

    return (
        <div>
        {/* Navbar */}
        <Navbar />

        {/* Formulario de Compra */}
        <div className="container mt-5">
            <h1>Formulario de Compra</h1>
            <form onSubmit={handleSubmit}>
            <div className="mb-3">
                <label htmlFor="direccion" className="form-label">
                Dirección:
                </label>
                <input
                type="text"
                id="direccion"
                name="direccion"
                className="form-control"
                value={formData.direccion}
                onChange={handleChange}
                required
                />
            </div>
            <div className="mb-3">
                <label htmlFor="nombre" className="form-label">
                Nombre:
                </label>
                <input
                type="text"
                id="nombre"
                name="nombre"
                className="form-control"
                value={formData.nombre}
                onChange={handleChange}
                required
                />
            </div>
            <div className="mb-3">
                <label htmlFor="cantidad" className="form-label">
                Cantidad:
                </label>
                <input
                type="number"
                id="cantidad"
                name="cantidad"
                className="form-control"
                value={formData.cantidad}
                onChange={handleChange}
                min="1"
                required
                />
            </div>
            <div className="mb-3">
                <label htmlFor="imagen" className="form-label">
                Imagen a sublimar:
                </label>
                <input
                type="file"
                id="imagen"
                name="imagen"
                className="form-control"
                accept="image/*"
                onChange={handleChange}
                />
            </div>
            <button type="submit" className="btn btn-primary">
                Agregar al carrito
            </button>
            </form>
        </div>
        </div>
    );
    }

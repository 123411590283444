import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar"; // Importa el componente Navbar
import "../css/carousel.css"; // Asegúrate de importar el archivo CSS para el carrusel

const URL_SHOWCAROUSEL = "https://evnd-database.com/roger/Proyecto_FI/backend_art/mostrarCarousel.php";
const URL_SHOWART = "https://evnd-database.com/roger/Proyecto_FI/backend_art/mostrararti.php";
const URL_SHOWCAT = "https://evnd-database.com/roger/Proyecto_FI/backend_art/mostrarCategorias.php";

export default function Menu() {
  const [productos, setProductos] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [carouselImages, setCarouselImages] = useState([]); // Imágenes del carrusel
  const navigate = useNavigate();

  // Obtener productos
  useEffect(() => {
    fetch(URL_SHOWART)
      .then((response) => response.json())
      .then((data) => setProductos(data))
      .catch((error) => console.error("Error fetching products:", error));
  }, []);

  // Obtener categorías
  useEffect(() => {
    fetch(URL_SHOWCAT)
      .then((response) => response.json())
      .then((data) => setCategorias(data))
      .catch((error) => console.error("Error fetching categories:", error));
  }, []);

  // Obtener imágenes para el carrusel (las últimas 3)
  useEffect(() => {
    fetch(URL_SHOWCAROUSEL)
      .then((response) => response.json())
      .then((data) => {
        if (data.success && Array.isArray(data.data)) {
          const ultimasTres = data.data.slice(-3).reverse(); // Selecciona las últimas 3 y las invierte
          setCarouselImages(ultimasTres);
        }
      })
      .catch((error) => console.error("Error fetching carousel images:", error));
  }, []);

  // Función para obtener el nombre de la categoría por su id
  const obtenerCategoria = (categoryId) => {
    const categoria = categorias.find((cat) => cat.id === categoryId);
    return categoria ? categoria.name : "Sin categoría";
  };

  return (
    <div>
      {/* Navbar */}
      <Navbar />

      {/* Carousel */}
      <div
        id="carouselExampleIndicators"
        className="carousel slide"
        data-bs-ride="carousel"
        data-bs-interval="3000"
      >
        <div className="carousel-indicators">
          {carouselImages.map((_, index) => (
            <button
              key={index}
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to={index}
              className={index === 0 ? "active" : ""}
              aria-current={index === 0 ? "true" : "false"}
              aria-label={`Slide ${index + 1}`}
            ></button>
          ))}
        </div>
        <div className="carousel-inner">
  {carouselImages.map((image, index) => (
    <div
      className={`carousel-item ${index === 0 ? "active" : ""}`}
      key={image.id}
    >
      <img
        src={`https://evnd-database.com/roger/Proyecto_FI/backend_art/banners/${image.image_path}`} // Ruta del backend
        className="d-block w-100 img-fluid carousel-img"
        alt={image.name}  
      />
      <div className="carousel-caption d-none d-md-block">
        <h5>{image.name}</h5>
        {/* <p>{image.description}</p> */}
      </div>
    </div>
  ))}
</div>

        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="prev"
        >
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="next"
        >
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>

      {/* Cards Section */}
      <div className="container mt-5">
        <div className="row">
          {productos.map((producto) => (
            <div className="col-md-4 mb-4" key={producto.id}>
              <div className="card">
                <img
                  src={`https://evnd-database.com/roger/Proyecto_FI/backend_art/${producto.image_url}`}
                  className="card-img-top"
                  alt={producto.name}
                />
                <div className="card-body">
                  <h5 className="card-title">{producto.name}</h5>
                  <p className="card-text">{producto.description}</p>
                  <p className="card-text">
                    <strong>Precio:</strong> ${producto.price}
                  </p>
                  <p className="card-text">
                    <strong>Categoría:</strong>{" "}
                    {obtenerCategoria(producto.category_id)}
                  </p>
                  <button
                    className="btn btn-primary"
                    onClick={() => navigate(`/articulo/${producto.id}`)}
                  >
                    Ver Detalles
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

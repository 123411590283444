import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// URLs de los endpoints del backend
const URL_SHOWCAROUSEL = "https://evnd-database.com/roger/Proyecto_FI/backend_art/mostrarCarousel.php";
const URL_ADDCAROUSEL = "https://evnd-database.com/roger/Proyecto_FI/backend_art/addDashboard.php";
const URL_EDITCAROUSEL = "https://evnd-database.com/roger/Proyecto_FI/backend_art/updateCarousel.php";
const URL_DELETECAROUSEL = "https://evnd-database.com/roger/Proyecto_FI/backend_art/deleteCarousel.php";

export default function CarouselCrud() {
  const navigate = useNavigate();
  const [imagenes, setImagenes] = useState([]); // Lista de imágenes del carrusel
  const [modalVisible, setModalVisible] = useState(false); // Control del modal
  const [isEditing, setIsEditing] = useState(false); // Estado para edición o creación
  const [formData, setFormData] = useState({
    id: "",
    name: "",
    description: "",
    image: null,
  });

  // Función para cargar las imágenes
  const fetchData = async () => {
    try {
      const response = await fetch(URL_SHOWCAROUSEL);
      const data = await response.json();

      // Verificar que los datos son un array y acceder a la propiedad 'data'
      if (data.success && Array.isArray(data.data)) {
        setImagenes(data.data); // Asignar el array a imagenes
      } else {
        console.error("La respuesta del backend no contiene un array válido:", data);
        setImagenes([]); // Inicializa el estado con un array vacío si hay un error
      }
    } catch (error) {
      console.error("Error al cargar las imágenes:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Control del modal
  const toggleModal = () => setModalVisible(!modalVisible);

  // Actualización de los campos del formulario
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Manejo de la imagen
  const handleFileChange = (e) => {
    setFormData({ ...formData, image: e.target.files[0] });
  };

  // Preparar el formulario para añadir una imagen
  const handleAdd = () => {
    setFormData({
      id: "",
      name: "",
      description: "",
      image: null,
    });
    setIsEditing(false);
    toggleModal();
  };

  // Preparar el formulario para editar una imagen
  const handleEdit = (item) => {
    setFormData({
      id: item.id,
      name: item.name,
      description: item.description,
      image: null, // Al editar, no mantengas la imagen seleccionada anteriormente
    });
    setIsEditing(true);
    toggleModal();
  };

  // Eliminar una imagen
  const handleDelete = async (id) => {
    if (!window.confirm("¿Estás seguro de eliminar esta imagen?")) return;
    try {
      const response = await fetch(URL_DELETECAROUSEL, {
        method: "POST", // Ajustado a POST según el backend
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ id }),
      });
      const data = await response.json();
      if (data.success) {
        alert(data.message);
        fetchData();
      } else {
        alert("Error al eliminar la imagen.");
      }
    } catch (error) {
      console.error("Error al eliminar:", error);
    }
  };

  // Enviar datos al backend para añadir o editar una imagen
  const handleSubmit = async (e) => {
    e.preventDefault();

    const url = isEditing ? URL_EDITCAROUSEL : URL_ADDCAROUSEL;
    const method = "POST";

    const formDataToSend = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      if (value !== null) {
        formDataToSend.append(key, value);
      }
    });

    try {
      const response = await fetch(url, { method, body: formDataToSend });
      const data = await response.json();

      if (data.success) {
        alert(data.message);
        fetchData();
        toggleModal();
      } else {
        alert("Error al procesar la solicitud.");
      }
    } catch (error) {
      console.error("Error en la solicitud:", error);
    }
  };

  return (
    <div>
      <h1 className="text-center">CRUD de Imágenes del Carrusel</h1>
      <button className="btn btn-primary" onClick={() => navigate("/adm")}>
        Regresar
      </button>
      <button className="btn btn-primary" onClick={handleAdd}>
        Añadir Imagen
      </button>
      <br />
      <br />
      <div className="row">
        {imagenes.length > 0 ? (
          imagenes.map((item) => (
            <div className="col-md-4 mb-4" key={item.id}>
              <div className="list-group">
                {/* Mostrar imagen del carrusel */}
                <img
                  src={`https://evnd-database.com/roger/Proyecto_FI/backend_art/banners/${item.image_path}`} // Asegúrate de que image_path es correcto
                  className="img-fluid"
                  alt={item.name} // Agregar un alt para accesibilidad
                />
                <div className="list-group-item">
                  <h5 className="mb-1">{item.name}</h5>
                  <p className="mb-1">{item.description}</p>
                  <button
                    className="btn btn-warning me-2"
                    onClick={() => handleEdit(item)}
                  >
                    Editar
                  </button>
                  <button
                    className="btn btn-danger"
                    onClick={() => handleDelete(item.id)}
                  >
                    Eliminar
                  </button>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No hay imágenes disponibles.</p>
        )}
      </div>

      {modalVisible && (
        <div className="modal show" style={{ display: "block" }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  {isEditing ? "Editar Imagen" : "Añadir Imagen"}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={toggleModal}
                ></button>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="modal-body">
                  <div className="mb-3">
                    <label className="form-label">Nombre</label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Descripción</label>
                    <input
                      type="text"
                      className="form-control"
                      name="description"
                      value={formData.description}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Imagen</label>
                    <input
                      type="file"
                      className="form-control"
                      onChange={handleFileChange}
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={toggleModal}
                  >
                    Cancelar
                  </button>
                  <button type="submit" className="btn btn-primary">
                    {isEditing ? "Guardar Cambios" : "Añadir"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import bcrypt from "bcryptjs"; // Importa bcryptjs
//import Navbar from './Navbar';

const URL_SHOW = "https://evnd-database.com/roger/Proyecto_FI/backend_art/mostrarUsua.php";
const URL_ADD = "https://evnd-database.com/roger/Proyecto_FI/backend_art/anadirUsua.php";
const URL_EDIT = "https://evnd-database.com/roger/Proyecto_FI/backend_art/backend/editarUsua.php";
const URL_DELETE = "https://evnd-database.com/roger/Proyecto_FI/backend_art/eliminarUsua.php";

const enviarData = async (url, method, data = null) => {
  const resp = await fetch(url, {
    method: method,
    headers: { 'Content-Type': 'application/json' },
    body: data ? JSON.stringify(data) : null
  });
  return await resp.json();
};

export default function Usuarioss() {
  const navigate = useNavigate();
  const [usuarios, setUsuarios] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    id: "",
    usuario: "",
    clave: "",
    nombre: "",
    apellidos: "",
    idTipoUsuario: ""
  });

  const datosusu = async () => {
    const respuesta = await enviarData(URL_SHOW, 'GET');
    setUsuarios(respuesta);
  };

  useEffect(() => {
    datosusu();
  }, []);

  const toggleModal = () => setModalVisible(!modalVisible);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleAdd = () => {
    setFormData({
      id: "",
      usuario: "",
      clave: "",
      nombre: "",
      apellidos: "",
      idTipoUsuario: ""
    });
    setIsEditing(false);
    toggleModal();
  };

  const handleEdit = (user) => {
    setFormData(user);
    setIsEditing(true);
    toggleModal();
  };

  const handleDelete = async (id) => {
    const response = await enviarData(URL_DELETE, 'DELETE', { id });
    if (response.success) {
      datosusu();
      alert(response.message);
    } else {
      alert("Error al eliminar el usuario");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Encriptar la contraseña si es una nueva o se ha cambiado
    if (formData.clave) {
      const hashedPassword = await bcrypt.hash(formData.clave, 10); // Encriptar la contraseña
      formData.clave = hashedPassword;
    }

    const url = isEditing ? URL_EDIT : URL_ADD;
    const method = isEditing ? 'PUT' : 'POST';

    const response = await enviarData(url, method, formData);
    if (response.success) {
      datosusu();
      alert(response.message);
      toggleModal();
    } else {
      alert("Ocurrió un error");
    }
  };

  return (
    <div>
      <h1 className="text-center">Usuarios</h1>
      <button className="btn btn-primary" onClick={() => navigate("/adm")}>
        Regresar
      </button>
      <button className="btn btn-primary" onClick={handleAdd}>Añadir Usuario</button>
      <br /><br />
      <div className="row">
        {usuarios.map((user) => (
          <div className="col-md-4 mb-4" key={user.id}>
            <div className="card shadow-sm">
              <div className="card-body">
                <h5 className="card-title">ID: {user.id}</h5>
                <p className="card-text"><strong>Usuario:</strong> {user.usuario}</p>
                <p className="card-text"><strong>Clave:</strong> {user.clave}</p>
                <p className="card-text"><strong>Nombre:</strong> {user.nombre} {user.apellidos}</p>
                <p className="card-text"><strong>Tipo de Usuario:</strong> {user.idTipoUsuario}</p>
                <button className="btn btn-warning me-2" onClick={() => handleEdit(user)}>Editar</button>
                <button className="btn btn-danger" onClick={() => handleDelete(user.id)}>Eliminar</button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {modalVisible && (
        <div className="modal show" style={{ display: "block" }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{isEditing ? "Editar Usuario" : "Añadir Usuario"}</h5>
                <button type="button" className="btn-close" onClick={toggleModal}></button>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="modal-body">
                  <div className="mb-3">
                    <label className="form-label">Usuario</label>
                    <input
                      type="text"
                      className="form-control"
                      name="usuario"
                      value={formData.usuario}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Clave</label>
                    <input
                      type="password"
                      className="form-control"
                      name="clave"
                      value={formData.clave}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Nombre</label>
                    <input
                      type="text"
                      className="form-control"
                      name="nombre"
                      value={formData.nombre}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Apellidos</label>
                    <input
                      type="text"
                      className="form-control"
                      name="apellidos"
                      value={formData.apellidos}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Tipo de Usuario</label>
                    <input
                      type="text"
                      className="form-control"
                      name="idTipoUsuario"
                      value={formData.idTipoUsuario}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={toggleModal}>Cancelar</button>
                  <button type="submit" className="btn btn-primary">{isEditing ? "Guardar Cambios" : "Añadir"}</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import 'bootstrap-icons/font/bootstrap-icons.css'; // Importa Bootstrap Icons

export default function Navbar() {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState(""); // Estado para almacenar el término de búsqueda
  const [autenticado, setAutenticado] = useState(false); // Estado de autenticación
  const [cartCount, setCartCount] = useState(0); // Estado para el total de artículos en el carrito

  // Verificar si el usuario está autenticado al cargar la página
  useEffect(() => {
    const isAuthenticated = localStorage.getItem("autenticado");
    if (isAuthenticated) {
      setAutenticado(true); // El usuario está autenticado
    }

    // Asegurarse de que Bootstrap JS esté cargado para los toggles
    const bootstrapScript = document.createElement('script');
    bootstrapScript.src = "https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha1/dist/js/bootstrap.bundle.min.js";
    bootstrapScript.async = true;
    document.body.appendChild(bootstrapScript);

    return () => {
      // Limpiar el script de Bootstrap al desmontar el componente
      document.body.removeChild(bootstrapScript);
    };
  }, []);

  // Función para manejar la búsqueda
  const handleSearch = (e) => {
    e.preventDefault(); // Prevenir el comportamiento por defecto del formulario
    if (searchTerm) {
      // Redirigir a la página de resultados de búsqueda con el término
      navigate(`/buscar?query=${searchTerm}`);
    }
  };

  // Función para manejar el cierre de sesión
  const handleLogout = () => {
    // Elimina el token o cualquier dato relacionado con la autenticación del localStorage
    localStorage.removeItem("autenticado"); // Elimina el estado de autenticación
    setAutenticado(false); // Cambia el estado de autenticación
    navigate("/lgn"); // Redirige al login
  };

  // Función para obtener el total de artículos en el carrito
  const fetchCartCount = async () => {
    try {
      const response = await fetch("https://evnd-database.com/roger/Proyecto_FI/backend_art/mostrarCarrito.php");
      if (!response.ok) {
        throw new Error("Error al obtener los datos del carrito");
      }
      const data = await response.json();
      if (data.success) {
        // Calcular la cantidad total de artículos
        const totalItems = data.data.reduce((sum, item) => sum + parseInt(item.cantidad, 10), 0);
        setCartCount(totalItems);
      } else {
        throw new Error(data.message || "Error desconocido");
      }
    } catch (err) {
      console.error("Error al obtener el carrito:", err.message);
    }
  };

  // Cargar la cantidad de artículos en el carrito al montar el componente
  useEffect(() => {
    fetchCartCount();
  }, []);

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light custom-navbar">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img src="/artsublim1.png" alt="Arte Sublimado" className="navbar-logo" />
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <i className="bi bi-list"></i>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <button className="nav-link" onClick={() => navigate("/")}>Inicio</button>
              </li>
              <li className="nav-item">
                <button className="nav-link" onClick={() => navigate("/cats")}>Categorías</button>
              </li>
              <li className="nav-item">
                <button className="nav-link" onClick={() => navigate("/abo")}>Quiénes somos</button>
              </li>
            </ul>
            {/* Formulario de búsqueda */}
            <form className="d-flex ms-auto" onSubmit={handleSearch}>
              <input
                type="text"
                className="form-control me-2 search-input"
                placeholder="Buscar..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)} // Actualizar el término de búsqueda
              />
              <button className="btn btn-outline-light search-button" type="submit">
                <i className="bi bi-search"></i>
              </button>
            </form>
          </div>
          {/* Botones del carrito y cierre de sesión */}
          {autenticado && (
            <div className="navbar-actions">
              <button
                className="action-btn cart-btn"
                onClick={() => navigate("/carrito")}
              >
                <i className="bi bi-cart-fill"></i>
                <span className="cart-count">{cartCount}</span>
              </button>
              <button
                className="action-btn"
                onClick={handleLogout}
              >
                <i className="bi bi-box-arrow-right"></i>
              </button>
            </div>
          )}
        </div>
      </nav>

      {/* Estilos CSS dentro del mismo archivo */}
      <style>{`
        /* Estilos generales */
        .custom-navbar {
          background-color: #6a4c9c;
          color: white;
          padding: 10px 20px;
        }

        .navbar-logo {
          height: 50px;
        }

        .navbar-toggler {
          background: none;
          border: none;
          color: white;
          font-size: 30px;
        }

        .navbar-nav .nav-item {
          margin-right: 15px;
        }

        .nav-link {
          color: white;
          text-decoration: none;
          font-weight: bold;
        }

        .nav-link:hover {
          color: #f1c6f0;
        }

        /* Estilos para el formulario de búsqueda */
        .search-input {
          padding: 5px;
          border: none;
          border-radius: 5px;
          font-size: 16px;
        }

        .search-button {
          background-color: #f1c6f0;
          border: none;
          padding: 6px 10px;
          margin-left: 10px;
          border-radius: 5px;
          cursor: pointer;
        }

        .search-button i {
          color: #6a4c9c;
        }

        /* Botones de la acción (carrito y logout) */
        .navbar-actions {
          display: flex;
          align-items: center;
        }

        .action-btn {
          background: none;
          border: none;
          color: white;
          font-size: 1.5rem;
          margin-left: 20px;
          cursor: pointer;
        }

        .action-btn:hover {
          color: #f1c6f0;
        }

        /* Estilo específico para el carrito */
        .cart-btn {
          position: relative;
        }

        .cart-count {
          position: absolute;
          top: -5px;
          right: -10px;
          background-color: red;
          color: white;
          border-radius: 50%;
          padding: 2px 6px;
          font-size: 12px;
        }

        /* Responsividad: ajustes para pantallas pequeñas */
        @media (max-width: 768px) {
          .navbar-nav {
            margin-top: 10px;
            text-align: center;
            width: 100%;
          }

          .nav-item {
            margin: 10px 0;
          }

          .navbar-toggler {
            display: block;
          }

          .navbar-collapse {
            flex-grow: 0;
          }

          .search-input {
            width: 70%;
            margin-right: 10px;
          }

          .navbar-actions {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-top: 10px;
          }

          .action-btn {
            margin-left: 0;
            margin-top: 10px;
          }
        }

        /* Para cuando el navbar se expande en dispositivos móviles */
        .navbar-collapse.show {
          display: block !important;
        }
      `}</style>
    </>
  );
}
